import React from "react";
import { useLocation } from "react-router-dom";
import "./BrowserPlot.css";

function BrowserPlot() {
    const location = useLocation();
    const { output } = location.state || { output: "" };

    return (
        <div>
            <h1>Browser Plot</h1>
            {output.includes(".html") ? (
                <iframe
                    src={`${output}`} // Make sure the `output` contains the URL
                    title="Browser Plot"
                    style={{ width: "100%", height: "100vh", border: "none" }}
                />
            ) : (
                <div>No valid plot data found.</div>
            )}
        </div>
    );
}

export default BrowserPlot;
