import React from "react";
import { useLocation } from "react-router-dom";
import "./BrowserPlot.css";
import backendBaseUrl from "./config";

function GjEnginePlot() {
    const location = useLocation();
    const { scriptOutput, htmlFileName } = location.state || {};
    
    const plotUrl = htmlFileName ? `${backendBaseUrl}/Images/${htmlFileName}` : '';

    return (
        <div className="browser-plot-container">
            <div className="browser-plot-content">
                <h1>GJ Engine Plot</h1>
                <div className="script-output">
                    <h2>Script Output:</h2>
                    <pre>{scriptOutput}</pre>
                </div>
                <div className="plot-container">
                    <h2>Generated Plot:</h2>
                    {plotUrl ? (
                        <iframe
                            src={plotUrl}
                            title="GJ Engine Plot"
                            className="gj-engine-plot-iframe"
                            style={{ width: "100%", height: "600px", border: "none" }}
                        />
                    ) : (
                        <p>No plot generated.</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default GjEnginePlot;